import React, { FC } from 'react';
import { graphql } from 'gatsby';

import DangerouslySetInnerHtml from 'layout/DangerouslySetInnerHtml';
import Layout from 'layout/Layout';
import Image from 'common/Image';
import PageSchema from 'common/PageSchema';
import Seo from 'common/Seo';
import Footer from 'components/Footer';
import Header from 'components/Header';
import SoBanner from 'components/SoBanner';

import { lineBreakToBr } from '../../utils/functions';
import { FutureProps } from './models';

import './Future.scss';

const Future: FC<{ data: FutureProps }> = ({
  data: {
    future: {
      urls,
      seo,
      intro1,
      intro2,
      intro3,
      tile,
      title1,
      description1,
      title2,
      description2,
      disclaimer,
      imageDesktop,
      imageMobile,
      image,
      extraClass,
      content,
      pixel,
    },
    footer,
    header,
    siteSettings,
  },
}) => {
  const { title: seoTitle, keywords: seoKeywords, description: seoDescription } = seo;

  return (
    <Layout headerTransparent {...{ pixel }}>
      {header && <Header data={header} settings={siteSettings} />}
      <Seo {...{ urls, seo }} />
      <PageSchema
        type="WebPage"
        name={seoTitle}
        data={{
          metaTitle: seoTitle,
          metaDescription: seoDescription,
          metaKeywords: seoKeywords,
        }}
      />
      <div
        className={`mx-auto section-container future ${extraClass ? `future--${extraClass}` : ''}`}
      >
        {content?.map((data, index) => (
          <SoBanner data={data} index={index} />
        ))}

        <div className="container-fluid px-0">
          <div className="row mx-0">
            <div className="col px-0">
              {imageDesktop && (
                <Image
                  imageData={imageDesktop}
                  alt={imageDesktop.altText}
                  className="d-none d-md-block"
                />
              )}
              {imageMobile && (
                <Image
                  imageData={imageMobile}
                  alt={imageMobile.altText}
                  className="d-block d-md-none"
                />
              )}
            </div>
          </div>
          <div className="row mx-0 pt-4">
            <div className="col px-0 text-center">
              <DangerouslySetInnerHtml
                html={intro1}
                element="div"
                className="future__intro-1 px-5"
              />
              <DangerouslySetInnerHtml
                html={intro2}
                element="div"
                className="future__intro-2 px-5"
              />
              <DangerouslySetInnerHtml
                html={intro3}
                element="div"
                className="future__intro-3 px-5 pt-4"
              />
              <DangerouslySetInnerHtml html={tile} element="div" className="future__tile mt-5" />
            </div>
          </div>
          <div className="row mx-0 mt-5">
            <div className="col px-0 px-5 text-center text-md-start">
              <DangerouslySetInnerHtml html={title1} element="h3" className="future__title" />
              {description1 && (
                <DangerouslySetInnerHtml
                  html={lineBreakToBr(description1)}
                  element="div"
                  className="future__description px-0 px-md-4 pt-4"
                />
              )}
            </div>
          </div>
          <div className="row mx-0 mt-5">
            <div className="col-12 col-md-6 px-5 text-center text-md-start">
              <DangerouslySetInnerHtml html={title2} element="h3" className="future__title" />
              {description2 && (
                <DangerouslySetInnerHtml
                  html={lineBreakToBr(description2)}
                  element="div"
                  className="future__description px-0 px-md-4 pt-4"
                />
              )}
            </div>
            <div className="col-12 col-md-6">
              {image && <Image imageData={image} alt={image.altText} />}
            </div>
          </div>
          <div className="row mx-0">
            <div className="col future__disclaimer px-5 pb-5">{disclaimer}</div>
          </div>
        </div>
      </div>
      {footer ? <Footer data={footer} /> : <></>}
    </Layout>
  );
};

export const query = graphql`
  query ($url: String, $lang: String) {
    future(url: { eq: $url }) {
      extraClass
      content {
        title
        subtitle
        variant
        containerText
        legalText
        pageSize
        containerImage {
          altText
          fallbackUrl
          desktop {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 1920)
            }
          }
        }
        imagePage {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
        imageButtonPopup {
          url
          altText
        }
        extraClass
        showPager
        showThumbs
        showArrows
        showStatus
        infiniteLoop
        items {
          variant
          extraClass
          legalText
          bannerText
          buttonType
          videoBanner {
            altText
            fallbackUrl
            url
          }
          videoBannerMobile {
            altText
            fallbackUrl
            url
          }
          button {
            url
            icon
            name
            published
            udi
            queryString
          }
          imageDesktop {
            altText
            desktop {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, width: 1920)
              }
            }
          }
          imageMobile {
            altText
            desktop {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, width: 768)
              }
            }
          }
          imageProduct {
            altText
            desktop {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, width: 768)
              }
            }
          }
          imageRecipe {
            altText
            desktop {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, width: 768)
              }
            }
          }
        }
      }
      urls {
        lang
        href
      }
      seo {
        ...SEOStructureFragment
      }
      intro1
      intro2
      intro3
      tile
      title1
      description1
      title2
      description2
      disclaimer
      pixel {
        nameEvent
        contentId
        contentName
        contentValue
        contentCurrency
      }
      imageDesktop {
        desktop {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 1300)
          }
        }
        mobile {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 1300)
          }
        }
        tablet {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 1300)
          }
        }
      }
      imageMobile {
        desktop {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 480)
          }
        }
        mobile {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 480)
          }
        }
        tablet {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 480)
          }
        }
      }
      image {
        desktop {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 420)
          }
        }
        mobile {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 420)
          }
        }
        tablet {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 420)
          }
        }
      }
    }
    header(lang: { eq: $lang }) {
      logo {
        desktop {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 200)
          }
        }
        mobile {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 70)
          }
        }
        tablet {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 70)
          }
        }
      }
      socialMedia {
        name
        url
        queryString
      }
      navs {
        name
        url
        queryString
      }
      sidebar {
        url {
          url
        }
        image {
          desktop {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 60)
            }
          }
          mobile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 60)
            }
          }
          tablet {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 60)
            }
          }
        }
        url {
          url
        }
      }
    }
    siteSettings {
      siteName
      defaultLang
      popupLanguage
      avaliableLanguages {
        url
        code
        title
        longTitle
      }
    }
    footer(lang: { eq: $lang }) {
      logo {
        desktop {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 150)
          }
        }
        mobile {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 150)
          }
        }
        tablet {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 150)
          }
        }
      }
      extraLogo {
        childImageSharp {
          gatsbyImageData(quality: 100)
        }
      }
      navs {
        name
        url
      }
      contact
      copyright
      socialMedia {
        iconImage {
          url
          altText
          fallbackUrl
        }
        iconLink {
          url
          name
          icon
        }
      }
    }
  }
`;

export default Future;
